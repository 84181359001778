import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Inject,
  input,
  output,
  signal,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Analytics, ANALYTICS } from '@smw/analytics-front';
import { AuthenticationService } from '@smw/authentication-front';
import { NavService, RoutePipe } from '@smw/navigation-front';
import { Space, SpaceStore, UserStore } from '@smw/stores-front';
import { ButtonComponent, MenuDirective } from '@smw/ui-front';
import { IconComponent, MenuTriggerDirective } from '@smw/ui-front';

import { SidebarMenuComponent } from './menu/menu.component';

type NavItem = {
  icon: string;
  label: string;
  path: string;
  disabled?: boolean;
};

@Component({
  selector: 'smw-sidebar',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    MenuTriggerDirective,
    MenuDirective,
    IconComponent,
    ButtonComponent,
    SidebarMenuComponent,
    RoutePipe,
  ],
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private nav: NavService,
    private userStore: UserStore,
    private authService: AuthenticationService,
    private spaceStore: SpaceStore,
    private translateService: TranslateService,
    @Inject(ANALYTICS) private analytics: Analytics,
  ) {}

  currentUrl = signal('');

  smallDevice = input(true);
  mediumDevice = input(false);
  largeDevice = input(false);
  close = output();

  viewingAs = computed(() => this.spaceStore.mode());

  activePath = signal('');

  collaboratorNavItems = computed<NavItem[]>(() => {
    const items: NavItem[] = [
      {
        icon: 'hand-step',
        label: marker('sidebar_dashboard_item'),
        path: this.nav.routeOf('collaborator.dashboard'),
        //activePaths: ['/mon-profil-workoscope', '/motivations', '/tableau-de-bord'],
      },
    ];

    if (this.userStore.companyEnablesModules().includes('ai')) {
      items.push({
        icon: 'copilot',
        label: marker('sidebar_copilot_item'),
        path: this.nav.routeOf('collaborator.copilot'),
        disabled: false,
      });
    }

    if (this.userStore.companyEnablesModules().includes('interview')) {
      items.push({
        icon: 'conversations',
        label: marker('sidebar_conversations_item'),
        path: this.nav.routeOf('collaborator.conversations'),
      });
      items.push({
        icon: 'actions',
        label: marker('sidebar_objectives_item'),
        path: this.nav.routeOf('collaborator.objectives'),
      });
    }

    if (this.userStore.companyEnablesModules().includes('mobility')) {
      items.push({
        icon: 'boat-step',
        label: this.translateService.instant('sidebar_mobility_item'),
        path: 'parcours-mobilite',
        disabled: false,
      });
    }

    return items;
  });
  managerNavItems = computed<NavItem[]>(() => {
    const items: NavItem[] = [];

    if (this.userStore.companyEnablesModules().includes('interview')) {
      items.push({
        icon: 'conversations',
        label: marker('sidebar_conversations_item'),
        path: this.nav.routeOf('manager.conversations'),
        disabled: false,
      });
      items.push({
        icon: 'actions',
        label: marker('sidebar_objectives_item'),
        path: this.nav.routeOf('manager.objectives'),
      });
    }

    if (this.userStore.companyEnablesModules().includes('formations')) {
      items.push({
        icon: 'formation',
        label: marker('sidebar_training_item'),
        path: this.nav.routeOf('manager.formations'),
        disabled: false,
      });
    }

    return items;
  });
  hrNavItems = computed<NavItem[]>(() => [
    {
      icon: 'dashboard',
      label: marker('sidebar_dashboard_item_hr'),
      path: this.nav.routeOf('hr'),
      disabled: false,
    },
  ]);

  items = computed(() => {
    switch (this.viewingAs()) {
      case 'collaborator':
        return this.collaboratorNavItems();
      case 'manager':
        return this.managerNavItems();
      case 'hr':
        return this.hrNavItems();
      default:
        return [];
    }
  });

  fullname = computed(() => this.userStore.fullname());
  initials = computed(() => this.userStore.firstname()[0]?.toUpperCase() ?? '');
  roles = computed(() => this.userStore.roles());
  company = computed(() => this.userStore.company()?.name ?? '');

  isMenuOpened = signal(false);

  changeSpace(mode: Space): void {
    this.spaceStore.mode.set(mode);
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      this.analytics.disconnectIdentity();
      this.router.navigateByUrl('/authentification');
    });
  }

  /** Now, it is needed for a single menu component to be in 'active state' even for page of different URL.
   *  The current system didn't took that into account, since item directives doesn't update when changing URL
   *  and updating the active state of the menu items is impossible with computed values,
   *  here is a workaround to fix the issue for the concerned items
   */
  fixIncorrectActiveLinks(itemPath: string) {
    if (itemPath === '/espace-collaborateur/tableau-de-bord') {
      const url = this.router.url;
      if (
        url.includes('/mon-profil-workoscope') ||
        url.includes('/motivations') ||
        url.includes('/check-up')
      ) {
        return true;
      }
      return false;
    }
    return false;
  }
}
