<div class="checkbox" [class.disabled]="disabled()">
  @if (!disabled()) {
    <button [class.checked]="checked()"></button>
  }
</div>
@if (templateLabel()) {
  <label>
    <ng-container
      [ngTemplateOutlet]="templateLabel()!"
      [ngTemplateOutletContext]="templateLabelContext()"
    ></ng-container>
  </label>
} @else {
  <label class="body-md">{{ label() }}</label>
}
