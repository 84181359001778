import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { fromEvent, startWith, Subject, takeUntil } from 'rxjs';

@Directive({ selector: '[chameleon]', standalone: true })
export class ChameleonDirective implements AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private topBar?: HTMLElement | null;

  constructor(
    private renderer2: Renderer2,
    private elementRef: ElementRef<HTMLElement>,
  ) {}

  ngAfterViewInit(): void {
    // Timeout is a workaround to prevent wrong elementTop calculation after navigation
    setTimeout(() => {
      this.topBar = this.getTopBarElement();

      if (!this.topBar) {
        return;
      }

      const element = this.elementRef.nativeElement;
      const elementBefore = getComputedStyle(element, ':before');

      const elementBackgroundColor = this.getElementColor(element, elementBefore);

      fromEvent(window, 'scroll')
        .pipe(startWith(undefined), takeUntil(this.unsubscribe$))
        .subscribe(() => {
          if (
            this.hasColorsMismatch(elementBackgroundColor) &&
            this.isOverlaping(element, elementBefore)
          ) {
            this.changeTopbarColor(elementBackgroundColor);
          }
        });
    }, 0);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    if (this.topBar) {
      this.renderer2.removeStyle(this.topBar, 'background-color');
    }
  }

  private getTopBarElement(): HTMLElement | null {
    return document.querySelector('smw-header');
  }

  private parsePx(pixel: string): number {
    const value = parseInt(pixel, 10);
    return Number.isNaN(value) ? 0 : value;
  }

  private pseudoBeforeHasColor(pseudoBefore: CSSStyleDeclaration): boolean {
    return !!pseudoBefore?.backgroundColor && pseudoBefore.backgroundColor !== 'rgba(0, 0, 0, 0)';
  }

  private hasColorsMismatch(elementColor: string): boolean {
    const topBarColor = getComputedStyle(this.topBar!).backgroundColor;
    return this.colorOrDefault(topBarColor) !== this.colorOrDefault(elementColor);
  }

  private isOverlaping(element: Element, pseudoBefore: CSSStyleDeclaration): boolean {
    const topBarBoundingRect = this.topBar!.getBoundingClientRect();
    const topBarBottom = topBarBoundingRect.bottom;

    const elementBoundingRect = element.getBoundingClientRect();
    const elementTop = elementBoundingRect.top + this.parsePx(pseudoBefore.top);
    const elementBottom = elementBoundingRect.bottom;

    return elementTop <= topBarBottom && elementBottom > topBarBottom;
  }

  private changeTopbarColor(color: string) {
    this.renderer2.setStyle(this.topBar, 'background-color', this.colorOrDefault(color));
  }

  private getElementColor(element: Element, pseudoBefore: CSSStyleDeclaration): string {
    const elementStyle = getComputedStyle(element);
    const color = this.pseudoBeforeHasColor(pseudoBefore)
      ? pseudoBefore.backgroundColor
      : elementStyle.backgroundColor;
    return this.colorOrDefault(color);
  }

  private colorOrDefault(color: string): string {
    return color === 'rgba(0, 0, 0, 0)' ? 'rgb(255, 255, 255, 0)' : color;
  }

  private borderColorOrDefault(color: string): string {
    let { r, g, b, a } = this.parseRGBA(color);
    r = r - 30;
    g = g - 30;
    b = b - 30;
    a = 255;

    color = `rgba(${r}, ${g}, ${b}, ${a})`;

    if (this.isColorBlueish(color)) {
      return 'rgba(255, 255, 255, 0)';
    }

    return color === 'rgba(0, 0, 0, 0)' ? 'rgb(255, 255, 255, 255)' : color;
  }

  private parseRGBA(color: string): { r: number; g: number; b: number; a: number } {
    const colorValues = color.replace('rgb(', '').replace(')', '');
    const [r, g, b, a] = colorValues.split(',').map((num) => parseInt(num.trim()));
    return { r, g, b, a };
  }

  private isColorBlueish(color: string): boolean {
    const colorValues = color.replace('rgba(', '').replace(')', '');
    const [r, g, b] = colorValues.split(',').map((num) => parseInt(num.trim()));
    return b > r && b > g;
  }
}
